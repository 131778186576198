$primary-color:yellow;
@import 'animate.css';

@font-face {
    font-family:'Helvetica Neue' ;
    src: url('./assets/fonts/helvetica-neu.ttf') format('ttf');
}


@font-face {
    font-family:'La Belle Aurore' ;
    src: url('./assets/fonts/LaBelleAurore.woff2') format('woff2');
}


@font-face {
    font-family:'Coolvetica' ;
    src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
}

input,
textarea {
    font-family: 'Helvetica Neue';
}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family:'Coolvetica', sans-serif ;
    src: url('./assets/fonts/CoolveticaRg-Regular.woff') format('woff2');

}

.home, .projects, .contact {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
}