.project-page{
.text-zone{
    position: absolute;
    left: 10%;
    top:40%;
    transform: translateY(-50%);
    width: 40%;
    max-height: 90%;
}
h1{
    color:black;
    font-size: 53px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;
    text-align: center;
animation: fadeIn 1s 1.2s backwards;
}
}